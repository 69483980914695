
import { Component, Prop, Vue } from "vue-property-decorator";
import {
  fetchInterests,
  HobbyOrInterest,
} from "@/serviceClients/hobbyAndInterestWebServices";

import store from "@/store";
import { fetchUserInterests } from "./userCategoryWebServices";
import { AxiosResponse } from "axios";
import { pushError } from "@/core";

@Component
export default class SelectInterests extends Vue {
  @Prop() value!: string[];
  isLoading = false;

  interestData: HobbyOrInterest[] = [];

  get interests() {
    return this.interestData || [];
  }

  get interestIds() {
    return this.value;
  }

  set interestIds(value: string[]) {
    this.$emit("input", value);
  }

  async mounted() {
    this.isLoading = true;

    try {
      const userInterests = await fetchUserInterests(
        store.getters.module.user!.id
      );
      for (let res of userInterests.data) {
        this.interestIds.push(res.id);
      }

      const interests = await fetchInterests();
      this.interestData = interests.data;
    } catch (error) {
      pushError(error.response?.data?.error || error);
    } finally {
      this.isLoading = false;
    }
  }
}
