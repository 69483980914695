import * as axios from 'axios';
import { Category } from '@/serviceClients/categoryWebServices';
import { HobbyOrInterest } from '@/serviceClients/hobbyAndInterestWebServices';

const url = `${process.env.VUE_APP_API_URL}/user`;

export function fetchUserCategories(userId: string) {
    return axios.default.get<Category[]>(`${url}/${userId}/categories`);
}

export function updateUserCategories(userId: string, categories: string[]) {
    return axios.default.put(`${url}/${userId}/categories`, categories);
}

export function fetchUserHobbies(userId: string) {
    return axios.default.get<HobbyOrInterest[]>(`${url}/${userId}/hobbies`);
}

export function updateUserHobbies(userId: string, hobbies: string[]) {
    return axios.default.put(`${url}/${userId}/hobbies`, hobbies);
}

export function fetchUserInterests(userId: string) {
    return axios.default.get<HobbyOrInterest[]>(`${url}/${userId}/interests`);
}

export function updateUserInterests(userId: string, interests: string[]) {
    return axios.default.put(`${url}/${userId}/interests`, interests);
}