
import { Component, Prop, Vue } from "vue-property-decorator";
import {
  getHeightInOtherScale,
  HeightScale,
  heightScaleOptions,
} from "../accountSetup/assistantHeightHelper";

@Component
export default class SelectHeight extends Vue {
  @Prop() value!: { value?: number; scale: HeightScale };
  @Prop() disabled!: boolean;

  prevSelectedHeightScale: HeightScale = "cm";
  selectedHeightScale: HeightScale = "cm";

  heightScaleChanged() {
    this.height = getHeightInOtherScale(
      this.height ?? 0,
      this.prevSelectedHeightScale,
      this.selectedHeightScale
    );
    this.prevSelectedHeightScale = this.selectedHeightScale;
    this.$forceUpdate();
  }

  get height() {
    return this.value.value;
  }

  set height(val: number | undefined) {
    this.prevSelectedHeightScale = this.selectedHeightScale;
    if (val !== undefined) {
      const emittedValue: { value?: number; scale: HeightScale } = {
        value: val,
        scale: this.selectedHeightScale,
      };
      this.$emit("input", emittedValue);
    }
  }

  get heightScaleOptions() {
    return heightScaleOptions;
  }

  formatter(val: number | undefined): string | undefined {
    return !!val ? Math.round(val).toString() : undefined;
  }
}
