
import { Component, Prop, Vue } from "vue-property-decorator";
import {
  updateUserInterests,
} from "./userCategoryWebServices";
import store from "@/store";
import SelectInterests from "./SelectInterests.vue";
import { pushError } from "@/core";

@Component({
  components: {
    SelectInterests
  },
})
export default class Hobbies extends Vue {
  private interestIds: string[] = [];
  isLoading = false;

  async mounted() {
  }

  onSubmit() {
    this.isLoading = true;

    updateUserInterests(store.getters.module.user!.id, this.interestIds)
      .finally(() => {
        this.isLoading = false;
      })
      .catch((error) => {
        pushError(error.response?.data?.error || error);
      });
  }
}
