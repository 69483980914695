
import { Component, Vue } from "vue-property-decorator";
import SelectLanguages from './SelectLanguages.vue';

@Component({
  components: {
    SelectLanguages
  },
})
export default class Languages extends Vue {
}
