
import { Component, Vue } from "vue-property-decorator";
import store from "../store/index";
import { setHourlyRate } from "./assistantDataWebServices";
import { pushError } from "@/core";

@Component
export default class HourlyRate extends Vue {
  model: number | string = "";
  isLoading = false;

  mounted() {
    this.model = store.state.module.user!.hourlyRate ?? "";
  }

  async onSubmit() {
    this.isLoading = true;
    try {
      await setHourlyRate(store.getters.module.user!.id, this.model as number);
      await store.dispatch.module.updateStoredUser({
        hourlyRate: this.model as number,
      });
    } catch (error) {
      pushError((error as any).response?.data?.error || error, {
        title: this.$t("An error occurred while saving"),
      });
    } finally {
      this.isLoading = false;
    }
  }
}
