
import { Component, Prop, Vue } from "vue-property-decorator";

import {
  updateUserHobbies,
} from "./userCategoryWebServices";
import SelectHobbies from "./SelectHobbies.vue";
import store from "@/store";
import { pushError } from "@/core";

@Component({
  components: {
    SelectHobbies
  },
})
export default class Hobbies extends Vue {
  private hobbyIds: string[] = [];
  isLoading = false;

  onSubmit() {
    this.isLoading = true;

    updateUserHobbies(store.getters.module.user!.id, this.hobbyIds)
      .finally(() => {
        this.isLoading = false;
      })
      .catch((error) => {
        pushError(error.response?.data?.error || error);
      });
  }
}
