
import { Component, Prop, Vue } from "vue-property-decorator";
import { LanguageLevel, saveLanguages, UserLanguage } from "../accountSetup/accountSetupService";
import { getLevelOptionsTranslations } from "../accountSetup/languageLevelHelper"
import store from "../store";
import LanguagesDropdown from 'vue-languages-dropdown';
import { pushError } from "@/core";

@Component({
  components: {
    LanguagesDropdown
  },
})
export default class SelectLanguages extends Vue {
  isLoading = false;
  languages: UserLanguage[] = [];
  isInitialized = false;
  @Prop() hasExternalSaveButton!: boolean;

  get languagesList() {
      return this.languages || [];
  }

  get levelOptions() {
      return getLevelOptionsTranslations(this);
  }

  async mounted() {
      this.languages = store.getters.module.user!.languages ?? [];
      this.isInitialized = true;
  }

  languageSelected(language: UserLanguage, value: { code: string }) {
      language.languageCode = value.code;
  }

  addClicked() {
      this.languages.push({
          languageCode: 'en',
          level: LanguageLevel.Intermediate
      });
  }

  deleteClicked(language: UserLanguage) {
      this.languagesList.splice(this.languagesList.indexOf(language), 1);
  }

  onSubmit() {
      this.isLoading = true;
      return saveLanguages(store.getters.module.user!.id, {
        languages: this.languagesList.map(l => { return {
          languageCode: l.languageCode,
          level: LanguageLevel[l.level] as any as LanguageLevel,
          userId: store.getters.module.user!.id
        };}),
      })
      .then(() => {
        store.dispatch.module.updateStoredUser({
            languages: this.languagesList
        })
        .catch((error) => {
          pushError(error.response?.data?.error || error);
        });
      })
      .catch((error) => {
        pushError(error.response?.data?.error || error);
      })
      .finally(() => {
        this.isLoading = false;
      });

  }
}
