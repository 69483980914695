
import { Component, Prop, Vue } from "vue-property-decorator";
import Multiselect from 'vue-multiselect';
import countries from "@/dictionaries/countries";

@Component({
  components: {
    Multiselect
  }
})
export default class CountrySelect extends Vue {
  @Prop() value?: string;
  @Prop() disabled!: boolean;

  get localState() {
    return this.options.find(o => o.value == this.value);
  }

  set localState(value: any) {
    this.$emit("input", value?.value);
  }

  options = countries;
}
