import countries from '@/dictionaries/countries';
import { Address } from '@/panel/userPanelWebServices';
import Vue from 'vue';
import { getGoogleApiKey } from './settingsHelper';

export async function geocodeAddress(address: Address) {
    await (Vue as any).loadScript(`https://maps.googleapis.com/maps/api/js?key=${getGoogleApiKey()}&libraries=&v=weekly`);

    const geocoder = new google.maps.Geocoder();

    let resolvePromise = (res: google.maps.LatLng) => { /* noop */ };
    let rejectPromise = (reson: string) => { /* noop */ };
    const promise = new Promise<google.maps.LatLng>((resolveFn, rejectFn) => {
        resolvePromise = resolveFn;
        rejectPromise = rejectFn;
    });

    const country = countries.find(c => c.value === address.country);

    geocoder.geocode({
        address: `${address.street} ${address.additionalInfo || ''}, ${address.city?.name}, ${country?.text}`
      }, (results: google.maps.GeocoderResult[]) => {
          if(results.length === 0) {
            rejectPromise("Geolocation not successful. Did you enter the correct address?");
            return;
          }
          const res = results[0].geometry.location;
          resolvePromise(res);
      });

    return promise;
}
