
import { Component, Prop, Vue } from "vue-property-decorator";
import {
  fetchHobbies,
  HobbyOrInterest,
} from "@/serviceClients/hobbyAndInterestWebServices";

import store from "@/store";
import { fetchUserHobbies } from "./userCategoryWebServices";
import { pushError } from "@/core";

@Component({
  components: {},
})
export default class SelectHobbies extends Vue {
  @Prop() value!: string[];
  isLoading = false;

  hobbyData: HobbyOrInterest[] = [];

  get hobbies() {
    return this.hobbyData || [];
  }

  get hobbyIds() {
    return this.value;
  }

  set hobbyIds(val: string[]) {
    this.$emit("input", val);
  }

  async mounted() {
    this.isLoading = true;

    try {
      const userHobbies = await fetchUserHobbies(store.getters.module.user!.id);
      for (let res of userHobbies.data) {
        this.hobbyIds.push(res.id);
      }

      const hobbies = await fetchHobbies();
      this.hobbyData = hobbies.data;
    } catch (error) {
      pushError(error.response?.data?.error || error);
    } finally {
      this.isLoading = false;
    }
  }
}
